<template>
  <div>
    <div v-for="(item, index) in carouselItems" :key="index">
      <b-carousel-slide>
        <template #img>
          <div v-if="item.link != null && item.link.trim() != ''">
            <a
              v-if="isExternalLink(item.link)"
              :href="item.link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="d-block img-fluid w-100"
                :src="item.imgSrc"
                :alt="item.alt || 'Hipotecaria Evoluciona'"
              />
            </a>
            <router-link v-else :to="item.link">
              <img
                class="d-block img-fluid w-100"
                :src="item.imgSrc"
                :alt="item.alt || 'Hipotecaria Evoluciona'"
              />
            </router-link>
          </div>

          <img
            v-else
            class="d-block img-fluid w-100"
            :src="item.imgSrc"
            :alt="item.alt || 'Hipotecaria Evoluciona'"
          />
        </template>
      </b-carousel-slide>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carouselItems: {
      type: Array,
      default: () => [
        {
          alt: null,
          imgSrc: null,
          link: null,
        },
      ],
    },
  },
  methods: {
    isExternalLink(link) {
      return /^(http|https):\/\//.test(link);
    },
  },
};
</script>
