<template id="navBar">
  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/"
        ><img src="img/logo_movil.svg" alt=""
      /></router-link>
      <button
        ref="navbar"
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src="img/icon_menu.svg" alt="" />
      </button>
      <div id="navbarSupportedContent" class="collapse navbar-collapse p-4">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 p-4">
          <!-- <li class="nav-item">
            <router-link to="/home-subsidio"
            ><img
                class="img-boton w-100 mb-1"
                src="img/btn/btn_subsidio.svg"
                alt=""
            /></router-link>
          </li> 
          <li class="nav-item">
            <router-link to="/home-hogar"
            ><img
                class="img-boton w-100 mb-1"
                src="img/btn/btn_hogar.svg"
                alt=""
            /></router-link>
          </li>
          <li class="nav-item">
            <router-link to="/home-empresas"
            ><img
                class="img-boton w-100 mb-2"
                src="img/btn/btn_empresa.svg"
                alt=""
            /></router-link>
          </li> -->
          <li>
            <router-link to="/home-subsidio">
              <button
                class="btn btn-primary w-100 btn-top-menu btn-top-fuchsia"
              >
                Subsidio Habitacional
              </button>
            </router-link>
          </li>
          <li>
            <router-link to="/home-hogar">
              <button class="btn btn-primary w-100 btn-top-menu btn-top-green">
                Crédito Hogar
              </button>
            </router-link>
          </li>
          <li>
            <router-link to="/home-empresas">
              <button class="btn btn-primary w-100 btn-top-menu btn-top-blue">
                Crédito Empresa
              </button>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link btn btn-movil" to="/Nosotros"
              >Nosotros
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link btn btn-movil"
              to="/preguntas-frecuentes"
              >Preguntas Frecuentes
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link btn btn-movil" to="/Asesoramos"
              >Te Asesoramos
            </router-link>
          </li>
          <li class="nav-item">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#portabilidad2"
              class="nav-link btn btn-movil"
            >
              Portabilidad
            </button>
          </li>
          <li class="nav-item">
            <router-link class="nav-link btn btn-movil" to="/formularios"
              >Formularios
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link btn btn-movil" href="/medios-de-pago"
              >Paga Aquí</a
            >
          </li>
          <li class="nav-item">
            <div class="d-flex w-100 flex-column">
              <a
                class="text-decoration-none d-flex justify-content-center"
                href="https://sucursal.hipotecariaevoluciona.cl"
              >
                <div class="virtual-branch">
                  <img src="img/virtual-branch.svg" alt="" />
                  <p class="text-blue">mi portal</p>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Modal -->
    <!-- Modal -->
    <div
      id="portabilidad2"
      class="modal fade md-bg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content mdb-bg">
          <div class="modal-body mdb-bg">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg close_color"></i>
            </button>
            <img src="img/pop_portabilidad.svg" alt="Portabilidad" />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
  </nav>
</template>

<script>
import { Collapse } from 'bootstrap';

export default {
  watch: {
    $route(to, from) {
      if (from.name !== null) {
        const navbarCollapse = document.getElementById(
          'navbarSupportedContent'
        );
        if (navbarCollapse.classList.contains('show')) {
          const bsCollapse = new Collapse(navbarCollapse, {
            toggle: false,
          });
          bsCollapse.hide();
        }
      }
    },
  },
};
</script>
