export const itemsDesktop = [
  {
    alt: 'Enlace Inmobiliario',
    imgSrc: 'img/carousel/20250109-01-banner-enlace-inmobiliario-desktop.jpg',
    link: 'https://hipotecariaevoluciona.enlaceinmobiliario.cl',
  },
  {
    alt: 'Subsidio',
    imgSrc: 'img/carousel/banner-subsidio-2507-1980x600.jpg',
    link: '/home-subsidio',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_1980x600.jpg',
    link: '',
  },
];

export const itemsMobile = [
  {
    alt: 'Enlace Inmobiliario',
    imgSrc: 'img/carousel/20250109-01-banner-enlace-inmobiliario-mobile.jpg',
    link: 'https://hipotecariaevoluciona.enlaceinmobiliario.cl',
  },
  {
    alt: 'Subsidio',
    imgSrc: 'img/carousel/banner-sucursal-2507_600x900.jpg',
    link: '/home-subsidio',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_600x900.jpg',
  },
];
